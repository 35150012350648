import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../../components/layouts/layout-ida"
import Seo from "../../../components/seo"

import EopCalloutCards from "../../../components/shared/generic/eop-callout-cards"
import akbcares from "../../../images/AkebiaCares-logo.png"
import quoteimg from "../../../images/ida/patient-id/img-richard-lund.png"

import CalloutIcon1 from "../../../images/icons/icon-callout-ida-efficacy.png"
import CalloutIcon2 from "../../../images/icons/icon-callout-ida-dosing.png"

const IdaPid = ({ location }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v4.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.; 2021.
            </>,
            <>
                Fishbane S, Pollack S, Feldman H, Joffe MM. Iron indices in
                chronic kidney disease in the National Health and Nutritional
                Examination Survey 1988-2004. <em>Clin J Am Soc Nephrol. </em>
                2009;4(1):57-61.
            </>,
            <>
                Webster AC, Nagler EV, Morton RL, Masson P. Chronic kidney
                disease. <em>Lancet</em>. 2017;389(10075):1238-1252.
            </>,
            <>
                Yagil Y, Fadem SZ, Kant KS, et al. Managing hyperphosphatemia in
                patients with chronic kidney disease on dialysis with ferric
                citrate: latest evidence and clinical usefulness.{" "}
                <em>Ther Adv Chronic Dis</em>. 2015;6(5):252-263.
            </>,
            <>
                Kalantar-Zadeh K, Streja E, Miller JE, Nissenson AR. Intravenous
                iron versus erythropoiesis-stimulating agents: friends or foes
                in treating chronic kidney disease anemia?{" "}
                <em>Adv Chronic Kidney Dis.</em> 2009;16(2):143-151.
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/about-auryxia/patient-profile"
            />
            <body id="ida-hcp-pid" className="ida" />
        </Helmet>

        <Seo
            title="Patient Profile"
            description="Discover the patient types to consider for treatment with AURYXIA. See Prescribing Information and Full Safety Information."
        />
        <section id="patient-id" className="col-10">
            <div className="flex-row">
                <div>
                    <h1>Who may be appropriate for&nbsp;AURYXIA?</h1>
                    <p>
                        There are many options when it comes to prescribing a
                        treatment for iron deficiency anemia.
                    </p>
                    <br />
                    <p>
                        AURYXIA is contraindicated in patients with iron
                        overload syndromes, eg, hemochromatosis.
                    </p>
                </div>
                <div>
                    <div>
                        <h2 className="ida">
                            Patients to consider for&nbsp;AURYXIA
                        </h2>

                        <p>
                            Adults with CKD stage 3, 4, or 5, with iron
                            deficiency anemia not on dialysis who<sup>1,2</sup>
                            *:
                        </p>

                        <ul>
                            <li>
                                Have commercial insurance <em>or</em>
                            </li>
                            <li>
                                Are non-compliant or unsuccessful with
                                traditional oral iron therapy <em>or</em>
                            </li>
                            <li>
                                Prefer oral administration <em>or</em>
                            </li>
                            <li>
                                Prefer treatment from the comfort of their home
                            </li>
                        </ul>
                        <p className="footnote">*CKD=chronic kidney disease.</p>
                    </div>
                    <p className="large-only">Not an actual patient.</p>
                </div>
            </div>
        </section>
        <section id="quote-slab" className="flood-ida col-10">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <img src={quoteimg} alt="" width="268" />
                </div>
                <div>
                    <blockquote>
                        <div className="blockquote_div">
                            <p className="ida desktop_span">
                                <span>&#x201C;</span>
                            </p>
                            <p className="ida">
                                <span className="mobile_span">&#x201C; </span>
                                With different oral formulations available,
                                specifically AURYXIA, you've got a medication
                                that's been FDA approved for the treatment of
                                anemia in CKD-NDD.<span>&#x201d;</span>
                            </p>
                        </div>
                        <cite>
                            &mdash;<strong> Richard J. Lund</strong>, MD,
                            Nephrologist
                        </cite>
                        <a
                            href="/iron-deficiency-anemia/about-auryxia/resources"
                            className="gradient-link ida"
                        >
                            Hear from your peers on why they prescribe AURYXIA
                        </a>
                    </blockquote>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="ida"
            callOneClass={"highlight"}
            callOneLink={"/iron-deficiency-anemia/efficacy/"}
            callOneContent={
                <>
                    <img src={CalloutIcon1} alt="" width="98" height="98" />
                    <h3>See what AURYXIA can do for your patients</h3>
                    <p>
                        AURYXIA increased Hb and iron parameters (TSAT and
                        ferritin) without IV iron or ESA use in a 16-week
                        pivotal&nbsp;trial.<sup>1,3-5</sup>
                    </p>
                    <button className="cta ida">
                        <span>EXAMINE EFFICACY</span>
                    </button>
                    <p className="center footnote">
                        IV iron use and ESA use were not allowed per trial
                        design and exclusion&nbsp;criteria.<sup>1</sup>
                    </p>
                </>
            }
            callTwoClass={"default"}
            callTwoLink={"/iron-deficiency-anemia/dosing/"}
            callTwoContent={
                <>
                    <img src={CalloutIcon2} alt="" width="98" height="98" />
                    <h3>Ready to prescribe?</h3>
                    <p>
                        AURYXIA provides the convenience of at-home treatment,
                        so it&#x2019;s important to learn about dosing and
                        administration.<sup>1</sup>
                    </p>
                    <button className="cta ida">
                        <span>GET DOSING INFORMATION</span>
                    </button>
                </>
            }
        />

        <section id="crosslink-slab" className="akb akb_moa_section">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <img src={akbcares} alt="AkebiaCares" height="81" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p className="boldTxt">
                        Connect with a personal Case Manager today!
                    </p>
                    <p className="med_width">
                        AkebiaCares personal Case Managers are standing by live
                        to answer your call and help your patients find
                        coverage.
                    </p>
                    <ul className="info_ul">
                        <li>
                            <a href="tel:18334253242">
                                1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)
                            </a>
                        </li>
                        <li>Monday&nbsp;-&nbsp;Friday</li>
                        <li className="hours_li">
                            8<span>AM</span>&nbsp;–&nbsp;8<span>PM</span>
                            &nbsp;EST
                        </li>
                    </ul>
                    <a
                        href="https://www.akebiacareshcp.com/"
                        className="cta akb"
                    >
                        <span>FIND SUPPORT OPTIONS</span>
                    </a>
                </div>
            </div>
        </section>

        <section id="interested-slab" className="col-6 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3>You may also be interested&nbsp;in:</h3>
                    <p>
                        <Link
                            to="/iron-deficiency-anemia/about-auryxia/mechanism-of-action/"
                            className="gradient-link ida"
                        >
                            MECHANISM OF ACTION
                        </Link>
                    </p>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default IdaPid
